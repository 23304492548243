module.exports = {
  siteTitle: 'Leo Au-Yeung', // <title>
  manifestName: 'Resume',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#112233',
  manifestThemeColor: '#445566',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/lion-head-clipart.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  firstName: 'Yiu-Wah (Leo)',
  lastName: 'Au-Yeung',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/leo-au-yeung/',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/LeoAuyeung',
    },
    {
      icon: 'fa-dev',
      name: 'Devpost',
      url: 'https://devpost.com/leoauyeung',
    },
    {
      icon: 'fa-medium',
      name: 'Medium',
      url: 'https://medium.com/@leoauyeung',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/leo.auyeung/',
    },
  ],
  email: 'leo297747@gmail.com',
  address: 'New York, NY',
};
