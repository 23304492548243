import React from 'react';

import Layout from '../components/Layout';

// import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">

      {/* ---------------------------------------- About ---------------------------------------- */}
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName} <span className="text-primary">{config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address} · {/*{config.phone} ·*/} <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>

          <p className="lead mb-5">
            I am an undergraduate senior in the Daedalus Honors Computer Science program at Hunter College, majoring in Computer Science and double minoring in Mathematics and Theatre.
          <br /><br />

          {/* With my most recent career position, I was able to gain my first professional tech internship as well as experience how the tech startup industry works first-hand.
          I am interested in various parts of the tech field and am actively exploring different interests and career paths as a Software Engineer, Technical Program Manager, and other similar positions.
          <br /><br /> */}

          I'm experienced in front-end development (ReactJS, GatsbyJS, vanilla JavaScript), back-end development (NodeJS, Python, Flask), and mobile development (React Native, Flutter, Swift).
          </p>

          <br />
          <p className="lead">
            Check out my social profiles below:
          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url} target="_blank" rel="noopener noreferrer">
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </section>

      <hr className="m-0" />

      {/* ---------------------------------------- Experience ---------------------------------------- */}
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          {/* -------------------- Qualtrics -------------------- */}
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Incoming Software Engineer</h3>
              <div className="subheading mb-3">
                <a href={'https://www.qualtrics.com/'} target="_blank" rel="noopener noreferrer">Qualtrics</a>
              </div>
              <div className="mb-3">
                Qualtrics, the leader in customer experience and creator of the Experience Management (XM) category,
                is changing the way organizations manage and improve the four core experiences of business––customer,
                employee, product, and brand.
                <br /><br />
                <a href={'http://bit.ly/qualtricsSWE2021'} target="_blank" rel="noopener noreferrer">
                  <i class="fab fa-linkedin"></i>&nbsp;Incoming Software Engineer @ Qualtrics
                </a>
              </div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Aug 2021</span>
            </div>
          </div>

          {/* -------------------- Qualtrics -------------------- */}
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer Intern</h3>
              <div className="subheading mb-3">
                <a href={'https://www.qualtrics.com/'} target="_blank" rel="noopener noreferrer">Qualtrics</a>
              </div>
              <div className="mb-3">
                SWE intern on the Developer Platform's Integrations team.
                <br /><br />
                <a href={'http://bit.ly/qualtricsSweIntern'} target="_blank" rel="noopener noreferrer">
                  <i class="fab fa-linkedin"></i>&nbsp;Incoming SWE Intern @ Qualtrics
                </a>
              </div>
              <p>
                • <u>Google Calendar &amp; Sheets Integrations</u>: Developed integrations with Google Calendar and Google Sheets for the survey platform as the end to end project owner using React.js.
                <br />
                • Maintained production level code quality by having over 90% code coverage with unit-tests written in Jest.
                <br />
                • Helped quantify the business value of the actions platform as part of the XM ecosystem to TPMs.
                <br />
                • Improved the quality of the actions platform by stress testing for bugs, inadequate documentation, and technical deficiencies within the XM-App framework in preparation for release to external developers.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Jun 2020 - Aug 2020</span>
            </div>
          </div>

          {/* -------------------- Numina -------------------- */}
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer Intern</h3>
              <div className="subheading mb-3">
                <a href={'https://numina.co/'} target="_blank" rel="noopener noreferrer">Numina</a>
              </div>
              <div className="mb-3">
                Deploy-anywhere sensor solution that gives cities unprecedented traffic data.
                <br /><br />
                <a href={'http://bit.ly/numinaSweIntern'} target="_blank" rel="noopener noreferrer">
                  <i class="fab fa-medium"></i>&nbsp;Software Engineering Internship: A Full-Stack Experience
                </a>
              </div>
              <p>
                • <u>Admin Panel</u>: Spearheaded the development of an internal tool as a web application using React.js, Node.js, and Apollo GraphQL Client aimed at reducing time and complexity taken to access the company's PostgreSQL database.
                <br />
                • Deployed the app to production within 3 months using AWS S3 and AWS CloudFront.
                <br />
                • App has been actively utilized across the company, reducing customer onboard time by over 90%, from upwards of 1 hour down to 5 minutes.
                <br />
                • <u>Sample Images</u>: Integrated full-stack functionality to update unique image data into the existing GraphQL API using Python, Flask, SQLAlchemy, Boto3, Docker, AWS S3, and AWS DynamoDB.
                <br />
                • <u>Dashboard</u>: Enhanced the customer dashboard, revamping data visualization interactivity, decreasing data visualization load time by 80%, and improving general UI/UX across the website.
                <br />
                • Worked in an Agile environment with daily stand-ups, weekly sprint planning, and weekly retrospective meetings.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Jan 2019 - Dec 2019</span>
            </div>
          </div>

          {/* -------------------- Google Mentorship Program -------------------- */}
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer Mentee</h3>
              <div className="subheading mb-3">
                <a href={'https://www.google.com/'} target="_blank" rel="noopener noreferrer">Google Mentorship Program</a>
              </div>
              <div className="mb-3">
                Selected as one of 20 students in the pilot group of the mentorship program at Google.
              </div>
              <p>
                • Paired with a Senior Software Engineer at Google for the 6-month program.
              <br />
                • Recreated the popular game 2048 with vanilla JavaScript, HTML, CSS, and deployed it using Google’s App Engine with Java.
              <br />
                • Focused on developing industry-level best practices including project storyboarding, code modularization, version control, unit-testing, and comprehensive code review.
              <br />
                • Wrote modularized code and tested in a test-driven development (TDD) pipeline with manual unit-tests.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Nov 2018 - May 2019</span>
            </div>
          </div>

          {/* -------------------- Pixel Academy -------------------- */}
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Counselor-In-Training</h3>
              <div className="subheading mb-3">
                <a href={'http://pixelacademy.org/'} target="_blank" rel="noopener noreferrer">Pixel Academy</a>
              </div>
              <p>
                Part of the August 2016 CIT intern program.
              </p>
              <p>
                • Assisted counselors in teaching students ages 7-14 in learning to use software such as Tinkercad, MinecraftEDU, Unreal Engine, and Unity
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Aug 2016</span>
            </div>
          </div>

        </div>
      </section>

      <hr className="m-0" />

      {/* ---------------------------------------- Education ---------------------------------------- */}
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          {/* -------------------- Hunter College -------------------- */}
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Hunter College</h3>
              <div className="subheading">Bachelor of Arts</div>
              <div className="subheading mb-3">Daedalus Honors Scholar</div>
              <div>Major: Computer Science </div>
              <div>Minor(s): Mathematics, Theatre</div>
              <p>GPA: 3.72</p>
              <p>
                Hunter Codefest (<i>Founder, Head Organizer</i>): Founded and oversaw the annual Hunter Codefest where 100+ students participate in a month-long hackathon sponsored by Google.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">August 2017 - May 2021</span>
            </div>
          </div>

          {/* -------------------- Stuyvesant High School -------------------- */}
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Stuyvesant High School</h3>
              <div className="subheading mb-3">Advanced Regents Diploma</div>
              <p>GPA: 3.70</p>
              <p>
                Stuyvesant Rowing Club: Co-President
                <br />
                Stuyvesant Red Cross Club: Member
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">September 2013 - June 2017</span>
            </div>
          </div>

        </div>
      </section>

      <hr className="m-0" />

      {/* ---------------------------------------- Skills ---------------------------------------- */}
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="skills"
      >
        <div className="w-100">
          <h2 className="mb-5">Skills</h2>

          {/* -------------------- Programming Languages -------------------- */}
          <div className="subheading mb-3">
            Programming Languages
          </div>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fab fa-js"></i>
              &nbsp;JavaScript
            </li>
            <li>
              <i className="fab fa-python"></i>
              &nbsp;Python
            </li>
            <li>
              <i className="fab fa-google"></i>
              &nbsp;Dart
            </li>
            <li>
              <i className="fab fa-apple"></i>
              &nbsp;Swift
            </li>
            <li>
              <i className="fas fa-robot"></i>
              &nbsp;C++
            </li>
            <li>
              <i className="fab fa-java"></i>
              &nbsp;Java
            </li>
            <li>
              <i className="fab fa-html5"></i>
              &nbsp;HTML
            </li>
            <li>
              <i className="fab fa-css3-alt"></i>
              &nbsp;CSS
            </li>
          </ul>
          <br />

          {/* -------------------- Tools & Frameworks -------------------- */}
          <div className="subheading mb-3">
            Tools &amp; Frameworks
          </div>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fab fa-react"></i>
              &nbsp;React.js, <i className="fab fa-node"></i> Node.js, <i className="fab fa-npm"></i> NPM
            </li>
            {/* <li>
              <i className="fab fa-sass"></i>
              &nbsp;Sass, <i className="fab fa-gulp"></i> Gulp
            </li> */}
            <li>
              <i className="fab fa-react"></i>
              &nbsp;React Native, <i className="fab fa-google"></i> Flutter, <i className="fab fa-app-store-ios"></i> iOS
            </li>
            <li>
              <i className="fab fa-python"></i>
              &nbsp;Flask, <i className="fab fa-python"></i> SQLAlchemy, <i className="fab fa-amazon"></i> Boto3
            </li>
            <li>
              <i className="fab fa-facebook"></i>
              &nbsp;GraphQL, <i className="fas fa-database"></i> SQL
            </li>
            <li>
              <i className="fab fa-aws"></i>
              &nbsp;DynamoDB, <i className="fas fa-database"></i> MongoDB, <i className="fas fa-database"></i> PostgreSQL
            </li>
            <li>
              <i className="fab fa-docker"></i>
              &nbsp;Docker
            </li>
            <li>
              <i className="fab fa-github"></i>
              &nbsp;Git/GitHub, <i className="fab fa-git"></i> VS Code's Git SCM
            </li>
          </ul>
          <br />

          {/* -------------------- Software -------------------- */}
          <div className="subheading mb-3">
            Software
          </div>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fab fa-microsoft"></i>
              &nbsp;Visual Studio
            </li>
            <li>
              <i className="fab fa-apple"></i>
              &nbsp;XCode
            </li>
            <li>
              <i className="fab fa-font-awesome-flag"></i>
              &nbsp;Clubhouse
            </li>
          </ul>
          <br />

          {/* -------------------- Operating Systems -------------------- */}
          <div className="subheading mb-3">
            Operating Systems
          </div>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fab fa-windows"></i>
              &nbsp;Windows
            </li>
            <li>
              <i className="fab fa-linux"></i>
              &nbsp;Linux
            </li>
            <li>
              <i className="fab fa-ubuntu"></i>
              &nbsp;Ubuntu
            </li>
            <li>
              <i className="fab fa-apple"></i>
              &nbsp;Mac OS
            </li>
          </ul>
          <br />

        </div>
      </section>

      <hr className="m-0" />

      {/* ---------------------------------------- Interests ---------------------------------------- */}
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="interests"
      >
        <div className="w-100">
          <h2 className="mb-5">Interests</h2>
          <p>
            Outside of my academic and professional life, I really enjoy eating and working out.
            I started working out in sophomore year of high school, and started powerlifting in freshman year of college.
            To fuel my ambitions, I eat... a lot!
            I pride myself on eating meals larger than the average meal, quicker than the average person. :)
          </p>
          <p className="mb-0">
            When I'm indoors, I like to catch up on the latest eSports news, NBA games, tech news, and anime.
            I enjoy playing video games here and there, specifically Minecraft and League of Legends.
            I also love spending time with friends and family whenever I get the chance to.
          </p>
          {/* Should insert some cool pictures here */}
        </div>
      </section>

      <hr className="m-0" />

      {/* ---------------------------------------- Hackathons ---------------------------------------- */}
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="hackathons"
      >
        <div className="w-100">
          <h2 className="mb-5">Hackathons</h2>
          <ul className="fa-ul mb-0">
            {/* <i className="fa-li fa fa-check text-success"></i>
            <i className="fa-li fa fa-check text-warning"></i>
            <i className="fa-li fa fa-check text-danger"></i> */}
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              <a href="https://bit.ly/changeHackNYU20" target="_blank" rel="noopener noreferrer">1<sup>st</sup> Place - HackNYU 2020</a>
            </li>
            <li>
              <i className="fa-li fa fa-check text-success"></i>
              Facebook New York Hackathon 2019
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              <a href="https://bit.ly/pactCFG19" target="_blank" rel="noopener noreferrer">2<sup>nd</sup> Place - JPMC: Code For Good Challenge 2019 - Jersey City</a>
            </li>
            <li>
              <i className="fa-li fa fa-check text-success"></i>
              SBUHacks 2019
            </li>
            <li>
              <i className="fa-li fa fa-check text-success"></i>
              HackNYU 2019
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              1<sup>st</sup> Place - Hunter Codefest 2019
            </li>
            <li>
              <i className="fa-li fa fa-check text-success"></i>
              HackCooper 2018
            </li>
            <li>
              <i className="fa-li fa fa-trophy"></i>
              Top 10 - CUNY Hackathon 2017
            </li>
          </ul>
          {/* Should insert some cool pictures here */}
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
